import {
  Alert,
  Container,
  FormSubmit,
  Loading,
  PasswordRepeatInput,
} from 'components';
import { PasswordInput } from 'components/PasswordInput';
import { PasswordRegExp } from 'consts';
import { Link, PageProps } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { useAuthCheck } from 'modules/authentication';
import { AuthLogo } from 'modules/navigation';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { auth, buttons, spacers } from 'styles';

// Page used for confirming email link and changing password, it is referenced in Firebase Authentication
// email templates as Action URL
const AuthenticationCheck: React.FC<PageProps> = () => {
  const {
    state: { error, success, loading },
    mode,
    methods,
    onSubmit,
  } = useAuthCheck();
  const { formatMessage } = useIntl();

  return (
    <Container className={auth.loginFlex}>
      <AuthLogo />

      <div css={auth.form}>
        <div css={auth.formTitle}>
          <h2 css={[spacers.bottom.medium]}>
            {mode === 'verifyEmail' ? (
              <FormattedMessage id="auth-check.title.verification" />
            ) : (
              <FormattedMessage id="auth-check.title.new" />
            )}
          </h2>
        </div>
        <Loading isLoading={loading}>
          {error && <Alert type="error" text={error} />}

          {mode === 'verifyEmail' && (
            <>
              {success && (
                <>
                  <Alert
                    type="success"
                    text={formatMessage({
                      id: 'auth-check.alert.email.success',
                    })}
                  />

                  <Link to="/prijava" css={buttons.primary.large}>
                    <FormattedMessage id="auth-check.back-to-login" />
                  </Link>
                </>
              )}
            </>
          )}

          {mode === 'resetPassword' && (
            <>
              {success && (
                <Alert
                  type="success"
                  text={formatMessage({
                    id: 'auth-check.alert.password.success',
                  })}
                />
              )}

              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  {!error && !success && (
                    <>
                      <PasswordInput
                        name="password"
                        label={formatMessage({
                          id: 'shared.input.password.label',
                        })}
                        placeholder={formatMessage({
                          id: 'auth-check.input.password.new',
                        })}
                        validationObject={{
                          required: formatMessage({
                            id: 'shared.validation.required',
                          }),
                          minLength: {
                            value: 8,
                            message: formatMessage({
                              id: 'shared.validation.password.length',
                            }),
                          },
                          validate: (value) =>
                            PasswordRegExp.test(value) ||
                            formatMessage({
                              id: 'shared.validation.password.length',
                            }),
                        }}
                        showPasswordScore
                      />

                      <PasswordRepeatInput
                        name="repeatPassword"
                        label={formatMessage({
                          id: 'auth-check.input.password.repeat',
                        })}
                        placeholder={formatMessage({
                          id: 'shared.input.password.placeholder',
                        })}
                        followName="password"
                      />
                    </>
                  )}

                  {success && (
                    <Link to="/prijava" css={buttons.primary.large}>
                      <FormattedMessage id="auth-check.back-to-login" />
                    </Link>
                  )}
                  {!error && !success && (
                    <FormSubmit
                      text={formatMessage({
                        id: 'auth-check.form.password.submit',
                      })}
                      stateIsChanging={loading}
                    />
                  )}
                </form>
              </FormProvider>
            </>
          )}
        </Loading>
      </div>

      <p>
        &copy; {new Date().getFullYear()}{' '}
        <FormattedMessage id="shared.copyright" />
      </p>
    </Container>
  );
};

export default AuthenticationCheck;
